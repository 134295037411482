<template>
  <v-snackbar
    :dark="false"
    timeout="-1"
    transition="v-slide-y-reverse-transition"
    color="#00000000"
    :value="dialog"
    content-class="pa-0 ma-0"
    bottom
    right
  >
    <div id="custom-snackbar-bg" :style="`background : ${ $vuetify.theme.dark ? '#272727' : '#f1f1f1' }`" >
      <v-alert class="ma-0" :type="type" dense prominent text outlined>
        {{ msg }}
      </v-alert>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      type: null,
      msg: null,
      dialog: false,
      options: {},
    };
  },
  methods: {
    open(msg, type = "info", options) {
      this.dialog = true;
      this.msg = msg;
      this.type = type;
      this.options = Object.assign(this.options, options);

      setTimeout(() => {
        this.dialog = false;
        // this.msg = null;
        // this.type = null;
        // this.options = Object.create(Object.prototype);
      }, 4000);
    },
  },
};
</script>

<style>
/* .theme--dark #custom-snackbar-bg {
  background: #272727;
}
.theme--ligth #custom-snackbar-bg {
  background: #f1f1f1;
} */

.v-snack__wrapper {
  display: block;
}
.custom-snackbar {
  /* min-width: 250px;
  max-width: 500px;
  position: fixed;
  z-index: 100;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%); */
}
</style>
